import React, { useState } from "react";

import PageWrapper from "../components/PageWrapper";
import { Pages } from "../components/ContentfulContents/contentfulPages";
import { Button, Container } from "react-bootstrap";
import { Title, Box } from "../components/Core";
import ReactHtmlParser from "react-html-parser";
import { graphql, useStaticQuery } from "gatsby";

const getCopy = graphql`
	query {
		allCopy: allContentfulCopy {
			edges {
				node {
					id
					copy {
						childMarkdownRemark {
							html
						}
					}
					title
				}
			}
		}
		images: allContentfulAsset {
			edges {
				node {
					id
					title
					file {
						url
						contentType
					}
					description
				}
			}
		}
		hsctWhitePaper: contentfulHsctWhitePaper {
			name
			hsctDocument {
				file {
					url
				}
			}
		}
	}
`;

const Whitepaper = () => {
	const {
		allCopy: { edges },
		images: { edges: images },
		hsctWhitePaper: {
			hsctDocument: {
				file: { url: pdf },
			},
		},
	} = useStaticQuery(getCopy);

	const { whitepaper } = Pages();

	const trial = edges.filter(
		({ node: { id } }) => id === "5cb68961-f434-5315-bba1-ab69d9fc8cd7"
	);

	const {
		node: {
			id: pageId,
			sectionTitle,
			copy: {
				childMarkdownRemark: { html: firstParagraph },
			},
			copy2: {
				childMarkdownRemark: { html: secondPara },
			},
		},
	} = whitepaper[0];

	const pageImage = images.filter(
		({ node: { id } }) => id === "84d3312e-ccce-507a-bd67-577ac893d197"
	);

	return (
		<>
			<PageWrapper>
				<Container>
					<div className="pt-5" />
					<div>
						{pageImage && (
							<img
								src={pageImage[0].node?.file?.url}
								alt={pageImage[0].node?.description}
							/>
						)}
						<Box py={[null, null, null, 5]} pr={5} pt={[4, null]}>
							{sectionTitle && (
								<>
									<Title variant="card" key={`${pageId}${sectionTitle}`}>
										{sectionTitle}
									</Title>
								</>
							)}
							{firstParagraph && (
								<>
									<span>{ReactHtmlParser(firstParagraph)}</span>
								</>
							)}
							{secondPara && (
								<>
									<span>{ReactHtmlParser(secondPara)}</span>
								</>
							)}
							<div>
								<Box mt={5} />

								<>
									<Button>
										<a href={pdf} style={{ border: "1px black" }}>
											DOWNLOAD WHITE PAPER
										</a>
									</Button>
								</>
							</div>
							<Box mt={5} />
							{trial && (
								<>
									{ReactHtmlParser(trial[0].node.copy.childMarkdownRemark.html)}
								</>
							)}
						</Box>
					</div>
				</Container>
			</PageWrapper>
		</>
	);
};
export default Whitepaper;
